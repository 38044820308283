<template>
  <div class="cmpn col">
    <b-card
      overlay
      img-src="../assets/home/screens-aragon-1.5-1280x594.jpg"
      img-alt="Header img"
      text-variant="white"
      title="Geekline - creativity &amp; innovation first!"
      sub-title="Passion to innovate!"
      style="text-align: center;">
      <!-- <b-card-text>Get started</b-card-text> -->
    </b-card>
    <b-card
      header-class="text-center"
      title="Geekline validation"
      style="background-color: lightblue; margin: 2px;">
      <template v-slot:header>
        <i class="mdi mdi-contact-mail" style="font-size: 32px;"></i>
      </template>
      <b-form-group
        id="validation"
        label="To be able to use the platform please enter your validation token:"
        label-for="validationToken"
        description="The validation token you have recived per eMail">
        <b-form-input
          id="validationToken"
          v-model="formData.token"
          type="text"
          required
          placeholder="Validation token"/>
      </b-form-group>
      <b-button @click="handleUpload">upload</b-button>
    </b-card>
  </div>
</template>
<script>
import { actions as accountActionsNames,
  getNamespacedName } from '@/store/modules/account/names'

export default {
  name: 'validation',
  data () {
    return {
      uploaded: false,
      formData: {
        token: ''
      }
    }
  }, // data
  created () {
    this.$tracker('event', 'screen_view', {
      'app_name': 'geekline-web',
      'screen_name': 'Validation'
    })
  },
  methods: {
    async handleUpload () {
      try {
        await this.$store.dispatch(getNamespacedName(accountActionsNames.UPLOAD_VALIDATION_TOKEN),
          this.formData.token)
        this.$bvToast.toast('Token successfully uploaded!')
        this.$router.push({ name: 'dashboard' })
      } catch (e) {
        this.$bvToast.toast(e.name + ': ' + e.message)
      } finally {

      }
    } // handleUpload
  }
}
</script>
<style>
.cmpn {
  margin-top: 85px;
}
</style>
